import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { HeroSection } from '../pageSections/HeroSection';
import { VideoSection } from '../pageSections/VideoSection';
import { BenefitsSection } from '../pageSections/BenefitsSection';
import { FeaturesSection } from '../pageSections/FeaturesSection';
import { CountriesSection } from '../pageSections/CountriesSection';
import { HelpSection } from '../pageSections/HelpSection';
import { OtherSolutionsSection } from '../pageSections/OtherSolutionsSection';
import { IntegrationsSection } from '../pageSections/IntegrationsSection';
import { MetricsSection } from '../pageSections/MetricsSection';
import { IndustriesSection } from '../pageSections/IndustriesSection';
import { EnterpriseSection } from '../pageSections/EnterpriseSection';
import { PaymentMethodsSection } from '../pageSections/PaymentMethodsSection';
import { ClientsSection } from '../pageSections/ClientsSection';
import { ApiDocsSection } from '../pageSections/ApiDocsSection';
import { EnterpriseBenefitsSection } from '../pageSections/EnterpriseBenefitsSection';
import { ComplementarySolutionsSection } from '../pageSections/ComplementarySolutionsSection';
import { Section } from '../components/Section';
import { Layout } from '../layout/Layout';
import { CountEmbedSection } from '../pageSections/CountEmbedSection';
import { PricingSection } from '../pageSections/PricingSection';

const sectionMapping = {
  Strapi_ComponentPageSectionsHeroSection: HeroSection,
  Strapi_ComponentPageSectionsVideoSection: VideoSection,
  Strapi_ComponentPageSectionsBenefitsSection: BenefitsSection,
  Strapi_ComponentPageSectionsFeaturesSection: FeaturesSection,
  Strapi_ComponentPageSectionsOtherSolutionsSection: OtherSolutionsSection,
  Strapi_ComponentPageSectionsSupportSection: HelpSection,
  Strapi_ComponentPageSectionsIntegrationsSection: IntegrationsSection,
  Strapi_ComponentPageSectionsMetricsSection: MetricsSection,
  Strapi_ComponentPageSectionsIndustriesSection: IndustriesSection,
  Strapi_ComponentPageSectionsEnterpriseSection: EnterpriseSection,
  Strapi_ComponentPageSectionsPaymentMethodsSection: PaymentMethodsSection,
  Strapi_ComponentPageSectionsClientsSection: ClientsSection,
  Strapi_ComponentPageSectionsEnterpriseBenefitsSection: EnterpriseBenefitsSection,
  Strapi_ComponentPageSectionsApiDocsSection: ApiDocsSection,
  Strapi_ComponentPageSectionsComplementarySolutionsSection: ComplementarySolutionsSection,
  Strapi_ComponentPageSectionsCountEmbedSection: CountEmbedSection,
  Strapi_ComponentPageSectionsPricingSection: PricingSection,
};

function mapCodename(name) {
  switch (name) {
    case 'increasecard':
      return 'card';
    case 'increasepay':
      return 'pay';
    default:
      return name;
  }
}

export default function ProductPage({ data }) {
  const {
    SeoInfo,
    sections,
    product,
    headerLayout,
    footerLayout,
  } = data.strapi.saasProductPage;
  const mappedCodename = mapCodename(product.codename);
  return (
    <Layout
      productName={mappedCodename}
      headerLayout={headerLayout}
      footerLayout={footerLayout}
    >
      <SEO
        title={SeoInfo.pageTitle}
        description={SeoInfo.pageDescription}
        keywords={SeoInfo.keywords}
      />
      {sections.map(s => {
        return (
          <Section
            mapping={sectionMapping}
            key={`${s.type}${s.id}`}
            {...s}
            product={{ ...product, codename: mappedCodename }}
          />
        );
      })}
    </Layout>
  );
}
export const query = graphql`
  fragment CTA on Strapi_ComponentGenericCta {
    text
    href
  }

  fragment Image on Strapi_UploadFile {
    url
    imageSharp {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }

  query($pageId: ID!) {
    strapi {
      saasProductPage(id: $pageId) {
        SeoInfo {
          pageDescription
          pageTitle
          keywords
        }
        product {
          name
          codename
          countries {
            name
            codename
          }
        }
        headerLayout
        footerLayout
        sections {
          type: __typename
          ... on Strapi_ComponentPageSectionsHeroSection {
            id
            title
            subtitle
            description
            image {
              url
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            primaryCTA {
              ...CTA
            }
            secondaryCTA {
              ...CTA
            }
          }
          ... on Strapi_ComponentPageSectionsMetricsSection {
            id
            metrics: metric {
              id
              name
              type
              gaugeText
              value: content
              valueType
              desktopColumns
            }
          }
          ... on Strapi_ComponentPageSectionsVideoSection {
            id
            title
            subtitle
            videoURL
            videoCTAText
            cta {
              ...CTA
            }
          }
          ... on Strapi_ComponentPageSectionsBenefitsSection {
            id
            title
            benefits {
              id
              name
              description
              image {
                url
                imageSharp {
                  publicURL
                }
              }
            }
          }
          ... on Strapi_ComponentPageSectionsFeaturesSection {
            id
            features {
              id
              title
              name
              description
              image {
                ...Image
              }
              ctaText
              ctaUrl
            }
          }
          ... on Strapi_ComponentPageSectionsClientsSection {
            id
            title
            description
            cta {
              ...CTA
            }
          }
          ... on Strapi_ComponentPageSectionsIndustriesSection {
            id
            title
            industries {
              id
              name
            }
          }
          ... on Strapi_ComponentPageSectionsPaymentMethodsSection {
            id
            title
            description
            paymentMethodsByCountry {
              id
              country {
                name
                codename
                IsoAlpha3
                unicodeFlagChar
              }
              columnStart
              columnEnd
              methods(sort: "priority") {
                id
                name
                codename
              }
            }
          }
          ... on Strapi_ComponentPageSectionsClientsSection {
            id
            title
            description
            cta {
              ...CTA
            }
          }
          ... on Strapi_ComponentPageSectionsSupportSection {
            id
            supportArticles: supportArticle {
              id
              title
              category
              url
            }
            title
            description
            cta {
              ...CTA
            }
          }
          ... on Strapi_ComponentPageSectionsEnterpriseSection {
            id
            description
            cta {
              ...CTA
            }
          }
          ... on Strapi_ComponentPageSectionsOtherSolutionsSection {
            id
            title
          }
          ... on Strapi_ComponentPageSectionsIntegrationsSection {
            id
            title
            description
            cta {
              ...CTA
            }
            image {
              ...Image
            }
          }

          ... on Strapi_ComponentPageSectionsCountEmbedSection {
            countEmbedUrl
          }

          ... on Strapi_ComponentPageSectionsPricingSection {
            countryPricing {
              id
              country {
                name
                codename
              }
              pricingTierBox {
                id
                colorScheme
                ctaHref
                ctaText
                price
                priceDescriptionLarge
                priceDescriptionSmall
                title
              }
              pricingText {
                title
                description
              }
            }
            title
          }
        }
      }
    }
  }
`;
