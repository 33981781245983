import React from 'react';
import styled from 'styled-components';
import { H3 } from '@increasecard/typed-components';
// import Img from 'gatsby-image';
import { CTAButton } from '../components/CTAButton';
import { ProductHeroBackground } from '../backgrounds/ProductHeroBackground';
import { Container } from '../components/Container';
import { SMALL, MEDIUM } from '../breakpoints';
import { DisplayTitle } from '../components/DisplayTitle';
// import { ResponsiveImage } from '../components/ResponsiveImage';
import { H1 } from '../components/Typography';

const HeroContainer = styled(Container)`
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 40px;
  @media (min-width: ${MEDIUM}) {
    padding-top: 80px;
  }
`;

const ProductTitle = styled(DisplayTitle)`
  font-size: 48px;
  line-height: 58px;
  @media (min-width: ${MEDIUM}) {
    font-size: 48px;
    line-height: 58px;
  }
`;

const ProductDesc = styled(H3)`
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.012em;
  @media (min-width: ${MEDIUM}) {
  }
`;

// const ImageWrapper = styled.div`
//   width: 90vw;
//   margin-left: auto;
//   margin-right: -16px;
//   margin-top: -20px;
//   position: relative;
//   top: 60px;

//   img {
//     width: 100%;
//     max-width: 840px;
//   }

//   @media (min-width: ${MEDIUM}) {
//     position: absolute;
//     margin-top: 80px;
//     top: -96px;
//     height: calc(100% + 22px);
//     right: calc(-420px + 25%);
//     width: auto;
//     display: flex;
//     align-items: start;

//     img {
//       width: auto;
//     }
//   }
// `;

const CTAGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
  @media (min-width: ${SMALL}) {
    width: 400px;
    flex-direction: row;
  }
`;

const ProductCTAButton = styled(CTAButton)`
  width: 100%;
  margin-top: 20px;
  @media (min-width: ${SMALL}) {
    width: max-content;
  }
  @media (min-width: ${MEDIUM}) {
  }
`;

const ColorBlock = styled.div`
  height: 172px;
  width: 100%;
  background: ${({ theme, variant }) =>
    variant === 'enterprise'
      ? theme.colorsNew.gray.black
      : theme.colorsNew.product.dark};
  display: none;

  @media (min-width: ${MEDIUM}) {
    display: block;
  }
`;

export function HeroSection({
  title,
  subtitle,
  description,
  image,
  primaryCTA,
  secondaryCTA,
  variant,
}) {
  // const imageSharp = image.childImageSharp || image.imageSharp.childImageSharp;
  return (
    <ProductHeroBackground variant={variant}>
      <HeroContainer>
        <ProductTitle dangerouslySetInnerHTML={{ __html: title }} />
        <H1
          as="h2"
          dangerouslySetInnerHTML={{ __html: subtitle }}
          weight="normal"
        />
        <ProductDesc
          dangerouslySetInnerHTML={{ __html: description }}
          weight="normal"
          style={{ marginBottom: '48px' }}
        />
        <CTAGroup>
          {primaryCTA && (<ProductCTAButton {...primaryCTA} />)}
          {secondaryCTA && (
            <ProductCTAButton {...secondaryCTA} type="product-outline" />
          )}
        </CTAGroup>
      </HeroContainer>
      <ColorBlock variant={variant} />
      {/* <ImageWrapper>
        <ResponsiveImage {...imageSharp.fluid} />
      </ImageWrapper> */}
    </ProductHeroBackground>
  );
}
